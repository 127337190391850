import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/router'
import('../public/css/global.css')
import {createPinia} from "pinia";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
const pinia = createPinia();
app.use(pinia)
app.use(router)
app.use(ElementPlus)


app.mount('#app')
