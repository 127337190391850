import {createRouter, createWebHistory} from "vue-router";
import {ElMessage} from "element-plus";

const routerHistory = createWebHistory();

const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: '/',
            component: () => import('@/view/Index.vue'),
            redirect: '/home',
            children: [
                {
                    path: '/home',
                    component: () => import('@/view/Home.vue'),
                    name: 'home',
                    meta: {title: '主页'}
                },
                {
                    path: '/upload',
                    component:() => import('@/view/uploadImage/UploadImage.vue'),
                    name: 'upload',
                    meta: {title: '上传图片'}
                },
                {
                    path: '/user',
                    component:() => import('@/view/me/User.vue'),
                    name: 'user',
                    meta: {title: '用户首页'}
                },
                {
                    path:'/dir',
                    component:() => import('@/view/dir/ImageDir.vue'),
                    name:'dir',
                    meta:{title:'目录页面'}
                },
                {
                    path:'/openApi',
                    component:() => import('@/view/openApi/OpenApi.vue'),
                    name:'openApi',
                    meta:{title:'开放平台'}
                }
            ]
        },
        {
            path:'/login',
            component: () => import('@/view/Login.vue'),
            name:'login',
            meta:{title:'登录'}
        }
    ]
})

export default router

router.beforeEach((to, from, next)=>{
    const toName = to.name;
    if(toName === 'login'){
        next()
    }else{
        const token = localStorage.getItem("token");
        if(token === '' || token === null){
            router.push("/login")
            ElMessage({
                showClose: true,
                message: 'Please login first..',
                type: 'error',
            })
        }else{
            next()
        }
    }
})
